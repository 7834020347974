<template>
  <v-card
    dark
    color="primary"
    class="card"
    tile
    elevation="8"
    :disabled="isLoading"
    @click="goToProject"
  >
    <header class="card-header">
      <v-card-text class="caption">
        <span class="opacity-3">
          created: {{ $moment(project.createdAt).format("hh:ss A, MMM D, Y") }}
        </span>
      </v-card-text>
      <v-card-actions>
        <v-btn
          icon
          color="white"
          @click.stop="removeProject(project.contentrProjectId)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
    </header>
    <v-card-title class="pt-6 pb-3 card-title">
      {{ project.name }}
    </v-card-title>
    <v-card-text class="text-center card-status">
      {{ project.status ? "ready" : "" }}
    </v-card-text>
    <v-card-text class="caption date-range opacity-7">
      ID: {{ project.contentrProjectId }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ProjectCard",
  props: {
    project: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    removeProject: {
      type: Function,
      required: false
    }
  },
  methods: {
    goToProject() {
      this.$router.push({
        name: "ContentrProject",
        params: {
          id: this.project.contentrProjectId
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.card-header {
  display: flex;
}
.card-title {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  height: 96px;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  word-break: keep-all;
}
.card-status {
}
.date-range {
  text-align: center;
}
</style>
