<template>
  <v-container>
    <v-card flat class="home-card" :loading="areProjectsLoading">
      <v-row style="width: 100%">
        <v-spacer />
        <v-col class="text-right">
          <v-btn
            x-large
            color="success"
            @click="$router.push({ name: 'ContentrUpload' })"
          >
            <v-icon>mdi-upload</v-icon>
            UPLOAD CONTENT
          </v-btn>
        </v-col>
      </v-row>
      <v-row style="width: 100%">
        <v-col>
          <v-divider />
        </v-col>
      </v-row>
      <v-row style="width: 100%">
        <v-col class="no-projects" v-if="projects.length < 1">
          <h1 class="empty">
            {{ $t("PROJECTS.NO_PROJECTS") }}
          </h1>
        </v-col>
        <v-col
          v-else
          cols="3"
          v-for="project in projects"
          :key="project.projectId"
        >
          <project-card :project="project" :remove-project="removeProject" />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import ProjectCard from "@/views/Contentr/components/ProjectCard";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
  name: "ContentrIndex",
  components: { ProjectCard },
  computed: {
    ...mapGetters({
      projects: "api/contentr/getProjects"
    }),
    ...mapState({
      areProjectsLoading: state => state.api.contentr.areProjectsLoading
    })
  },
  mounted() {
    if (this.projects.length < 1) {
      this.getProjects();
    }
  },
  beforeDestroy() {
    this.clearState();
  },
  methods: {
    ...mapActions({
      getProjects: "api/contentr/getProjects",
      deleteProject: "api/contentr/deleteProject"
    }),
    ...mapMutations({
      setAreProjectsLoading: "api/contentr/setProjectsLoading",
      clearState: "contentrProject/clearState"
    }),
    removeProject(id, method = this.deleteProject) {
      const action = () => {
        this.setAreProjectsLoading(true);
        method(id)
          .catch(error => {
            console.log(error);
            this.$notifier.notify({
              message: "Project can not be deleted!",
              color: "error"
            });
          })
          .then(() => {
            this.getProjects();
          })
          .finally(() => {
            this.setAreProjectsLoading(true);
          });
      };
      this.$modals.confirmation({
        action
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.no-projects {
  height: 100%;
}
.home-card {
  background: none;
  display: flex;
  flex-wrap: wrap;
}
.empty {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
</style>
